import $ from "jquery";
import FSTrack, { DebugType } from "./modules/fstrack";

$(
    () =>
    {
        const fstrack = FSTrack.getInstance();
        window[ "app" ] = fstrack; // Global reference for debugging
        fstrack.log( DebugType.Info, "FSTrack is starting..." );
        fstrack.run();
    }
);