import { API_URL } from "./constants";

/**
 * FSTrack API.
 */
export default class FSTrackApi
{
    private static _instance: FSTrackApi;

    private constructor()
    {
        FSTrackApi._instance = this;
    }

    /**
     * Get the current FSTrackApi instance.
     * @returns FSTrackApi instance.
     */
    public static getInstance()
    {
        if( FSTrackApi._instance == null )
            return new FSTrackApi();
        else
            return FSTrackApi._instance;
    }

    /**
     * Perform an API request.
     * @param params URL parameters.
     * @param method HTTP method.
     * @param data POST data, if any.
     */
    public async request( params: object, method: string = "GET", data?: string ) : Promise<FSTrackApiResult>
    {
        const urlParams: URLSearchParams = new URLSearchParams();
        for( const [ param, val ] of Object.entries( params ) )
            urlParams.append( param, val );

        const result = await fetch( API_URL + "?" + urlParams.toString() );
        const contentType = result.headers.get( "Content-Type" );
        if( contentType == "application/json" )
            return new FSTrackApiResult( result.status, await result.json() );
        else if( contentType == "application/octet-stream" )
            return new FSTrackApiResult( result.status, await result.arrayBuffer() );
        else
            return new FSTrackApiResult( result.status, await result.text() );
    }
}

/**
 * API result.
 */
export class FSTrackApiResult
{
    private _status: number;
    private _data: any;
    
    constructor( status: number, data?: any )
    {
        this._status = status;
        this._data = data;
    }

    /**
     * HTTP status code.
     */
    get status()
    {
        return this._status;
    }

    /**
     * API result data.
     */
    get data()
    {
        return this._data;
    }
}