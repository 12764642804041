import $ from "jquery";
import FSTrackApi from "./api";
import { AircraftDataMap } from "./constants";
import FSTrackLeaflet from "./leaflet";

/**
 * Debug types.
 */
export enum DebugType
{
    Info,
    Warning,
    Error
}

export default class FSTrack
{
    private static _instance: FSTrack;

    private _aircraftData: AircraftDataMap;
    private _leaflet: FSTrackLeaflet;

    public followAircraft: boolean = false;
    public persist: boolean = false;
    public aircraftRegFilter: string;
    public markerScale: number;

    private constructor()
    {
        FSTrack._instance = this;
    }

    /**
     * Get the current FSTrack instance.
     * @returns FSTrack instance.
     */
    public static getInstance()
    {
        if( FSTrack._instance == null )
            return new FSTrack();
        else
            return FSTrack._instance;
    }

    /**
     * Run FSTrack.
     */
    public async run()
    {
        if( window.getComputedStyle( document.body ).getPropertyValue( "--mobile-view" ) == "yes" )
        {
            $( "#map" ).addClass( "no-sidebar" );
            $( "#sidebar-right" ).removeClass( "opened" );
        }

        setTimeout(
            () =>
            {
                document.documentElement.style.setProperty( "--transition-timing", "0.5s" );
            },
            100
        );

        this._aircraftData = await ( await fetch( "aircraft.json" ) ).json();

        const urlParams = new URLSearchParams( window.location.search );
        if( urlParams.has( "reg" ) )
        {
            this.followAircraft = true;
            this.persist = true;
            this.aircraftRegFilter = decodeURIComponent( urlParams.get( "reg" )! );

            $( "#sidebar-right-follow-aircraft, #sidebar-right-persist" ).prop( "checked", true );
            $( "#filter-reg" ).val( this.aircraftRegFilter );
        }

        this._leaflet = FSTrackLeaflet.getInstance();
        this._leaflet.init();
    }

    /**
     * Aircraft configuration data.
     */
    get aircraftData()
    {
        return this._aircraftData;
    }

    public deselectFlight()
    {
        //
        // TODO
        //
    }

    public updateAircraft()
    {
        //
        // TODO
        //
    }

    /**
     * Output a debug message to the console.
     * @param type Debug type.
     * @param msg Debug message.
     * @param cat Debug category.
     */
    public log( type: DebugType, msg: string, cat: string = "FSTrack" )
    {
        let prefix: string = "%c[" + cat + "]%c ";
        let consoleMsgStyles: string[] = [];

        switch( type )
        {
            case DebugType.Info:
                prefix += "Info: ";
                consoleMsgStyles.push( "color: #0af", "color: initial" );
                console.info( prefix + msg, ...consoleMsgStyles );
                break;
            case DebugType.Warning:
                prefix += "Warning: ";
                consoleMsgStyles.push( "color: #ff0", "color: initial" );
                console.warn( prefix + msg, ...consoleMsgStyles );
                break;
            case DebugType.Error:
                prefix += "Error: ";
                consoleMsgStyles.push( "color: #f00", "color: initial" );
                console.error( prefix + msg, ...consoleMsgStyles );
                break;
        }
    }

    /**
     * Get FSTrackApi instance. Used for debugging purposes.
     * @returns FSTrackApi instance.
     */
    public getApi()
    {
        return FSTrackApi.getInstance();
    }
}