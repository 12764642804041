/**
 * API url.
 */
export const API_URL = "/api.php";

/**
 * Flight flags.
 */
export enum FlightFlags
{
    /**
     * Inactive flight.
     */
    Inactive = 1 << 0, // Unused

    /**
     * Commercial flight.
     */
    TypeCommercial = 1 << 1,

    /**
     * Military flight.
     */
    TypeMilitary = 1 << 2,

    /**
     * MLAT signal source.
     */
    SourceMLAT = 1 << 3,

    /**
     * Restrict photo.
     */
    RestrictPhoto = 1 << 4,

    /**
     * Transponder IDENT is active.
     */
    TransponderIdent = 1 << 5,

    /**
     * Restrict ATC data.
     */
    RestrictAtcData = 1 << 6,

    /**
     * Restrict MSFS product name.
     */
    RestrictTitle = 1 << 7,

    /**
     * Test flight.
     */
    TestFlight = 1 << 8
}

/**
 * Update timer interval, in milliseconds.
 */
export const UPDATE_INTERVAL = 1000;

/**
 * User flags.
 */
export enum UserFlags
{
    /**
     * Internal user.
     */
    Internal = 1 << 0
}

/**
 * Subscription plan IDs.
 */
export enum SubscriptionPlanIds
{
    Business = "prod_OpcMlD64tVBj0d",
    First = "prod_OpcQhaov4WBe1x",
    Airline = "prod_OpcRS9eIcy3Z49"
}

/**
 * Signal sources.
 */
export enum SignalSource
{
    ADSB = "ADS-B",
    MLAT = "MLAT"
};

/**
 * Aircraft icon.
 */
export type AircraftIcon = {
    file: string,
    size: [ width: number, height: number ]
};

/**
 * Aircraft config.
 */
export type AircraftConfig = {
    name: string,
    type: number,
    icon: AircraftIcon,
    modelRegEx: string,
    typeRegEx: string,
    source: SignalSource
};

/**
 * Aircraft data map.
 */
export type AircraftDataMap = { [k: string]: AircraftConfig };