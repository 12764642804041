import $ from "jquery";
import L from "leaflet";
import FSTrack from "./fstrack";

declare module "leaflet"
{
    export function hotline(data: number[][], options: any): any;
}

export default class FSTrackLeaflet
{
    private static _instance: FSTrackLeaflet;

    private _map: L.Map;    
    private _aircraftMarkerGroup: L.LayerGroup;
    private _trackingMarkerGroup: L.LayerGroup;

    private constructor()
    {
        FSTrackLeaflet._instance = this;
    }

    /**
     * Get the current FSTrackLeaflet instance.
     * @returns FSTrackLeaflet instance.
     */
    public static getInstance()
    {
        if( FSTrackLeaflet._instance == null )
            return new FSTrackLeaflet();
        else
            return FSTrackLeaflet._instance;
    }

    /**
     * Initialise FSTrack Leaflet objects.
     */
    public init()
    {
        const fstInst: FSTrack = FSTrack.getInstance();

        this._map = L.map(
            "map",
            {
                center: [ 0, 0 ],
                minZoom: 3,
                zoom: 3,
                zoomDelta: 0.5,
                zoomSnap: 0.3,
                zoomControl: false,
                worldCopyJump: true
            }
        );
        this._map.on(
            "click",
            () =>
            {
                if( fstInst.aircraftRegFilter == null )
                    fstInst.deselectFlight();
            }
        );
        this._map.on(
            "zoomend",
            () =>
            {
                const zoom = this._map.getZoom();
                if( zoom < 8.5 )
                    fstInst.markerScale = 0.85;
                else
                    fstInst.markerScale = 1;
                
                fstInst.updateAircraft();
            }
        );
        this._map.on(
            "move",
            () =>
            {
                $( ".aircraftMarkerIdent" ).each(
                    ( _, e ) =>
                    {
                        const marker = $( ".aircraftMarker[data-flight-id='" + $( e ).attr( "data-flight-id" ) + "']" );
                        const markerBounds = marker[ 0 ].getBoundingClientRect();
                        
                        $( e ).css( "top", ( markerBounds.top ) - ( ( markerBounds.width / fstInst.markerScale ) / 4 ) );
                        $( e ).css( "left", ( markerBounds.left ) - ( ( markerBounds.height / fstInst.markerScale ) / 4 ) );
                    }
                );
            }
        );

        L.tileLayer(
            "http://tile.openstreetmap.org/{z}/{x}/{y}.png",
            {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                keepBuffer: 20
            }
        ).addTo( this._map );
        
        L.control.zoom(
            {
                position: "topright"
            }
        ).addTo( this._map );

        navigator.geolocation.getCurrentPosition(
            ( d ) =>
            {
                this._map.setView( L.latLng( d.coords.latitude, d.coords.longitude ), 6, { animate: true } );

                let icon = L.icon({
                    iconUrl: "images/location.png",
                    iconSize: [ 15, 15 ],
                    shadowSize: [ 0, 0 ],
                    iconAnchor: [ 7.5, 7.5 ],
                    className: "locationMarker"
                });

                L.marker(
                    [
                        d.coords.latitude, d.coords.longitude
                    ],
                    {
                        icon: icon,
                        keyboard: false,
                        interactive: false
                    }
                ).addTo( this._map );
            }
        );

        this._aircraftMarkerGroup = L.layerGroup().addTo( this._map );
        this._trackingMarkerGroup = L.layerGroup().addTo( this._map );
    }
}